

































































import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
import { ElForm } from 'element-ui/types/form';
import { getWeatherTypeList } from '@/api/weather-type';

@Component({
  name: 'CrollForm',
})
export default class extends Vue {
  mounted() {
    this.getWeatherTypeList();
    this.connectWebsocket();
  }

  private validateDate = (rule: any, value: string, callback: Function) => {
    if (!(value) || !value[0] || !value[1]) {
      callback(new Error('수집 날짜를 선택해주세요.'));
    } else if (moment(value[1]).diff(moment(value[0]), 'day') > 90) {
      callback(new Error('수집 날짜는 최대 90일입니다.'));
    }
    callback();
  }

  private crollLoading = false;

  private crollDisabled = true;

  private websocket: any = null;

  private form = {
    searchDate: [moment().subtract(1, 'week').format('YYYYMMDD'), moment().format('YYYYMMDD')],
    newsNum: 100,
    searchType: '',
    searchValue: '',
  }

  private crollProgress = {
    step: 0,
  };

  private pickerMinDate: any = '';

  private weatherTypes = [];

  private datePickerOptions = {
    onPick: (range: any) => {
      this.pickerMinDate = range.minDate.getTime();
      if (range.maxDate) {
        this.pickerMinDate = '';
      }
    },
    disabledDate: (time: Date) => {
      if (this.pickerMinDate !== '') {
        const day90 = 90 * 24 * 3600 * 1000;
        let maxTime = this.pickerMinDate + day90;
        if (maxTime > new Date()) {
          maxTime = new Date();
        }
        return time.getTime() > maxTime;
      }
      return time.getTime() > Date.now();
    },
  };

  private rules = {
    searchDate: [
      {
        required: true,
        type: 'array',
        validator: this.validateDate,
        trigger: 'blur',
      },
    ],
    newsNum: [
      { required: true, message: '개수를 입력하세요.', trigger: 'blur' },
      {
        type: 'number',
        min: '1',
        max: '200',
        message: '1에서 200으로 입력하세요.',
        trigger: 'blur',
      },
    ],
    searchType: [
      { required: true, message: '기상영향을 선택하세요.', trigger: 'blur' },
    ],
  }

  private getWeatherTypeList() {
    getWeatherTypeList().then((res) => {
      this.weatherTypes = res.data;
    });
  }

  private connectWebsocket() {
    this.websocket = new WebSocket(process.env.VUE_APP_WEBSOCKET_URL);
    this.websocket.binaryType = 'arraybuffer';
    this.websocket.onclose = () => {
      this.$message.error('소켓 서버와 연결에 실패했습니다.');
      this.crollLoading = false;
      this.crollDisabled = true;
      this.crollProgress.step = 0;
    };
    this.websocket.onmessage = (event: any) => {
      const eventData = new TextDecoder('UTF-8').decode(event.data);
      const jsonData = JSON.parse(eventData);
      if (jsonData.status === 200) {
        this.crollProgress.step = jsonData.step;
        if (jsonData.step === 0) {
          this.crollLoading = false;
          this.crollDisabled = false;
        } else {
          this.crollLoading = true;
        }
        if (jsonData.step === 5) {
          setTimeout(() => {
            this.$alert('데이터 수집이 완료되었습니다.', '알림', {
              confirmButtonText: 'OK',
            });
            this.$emit('endCroll');
            this.crollProgress.step = 0;
            this.crollLoading = false;
            this.crollDisabled = false;
          }, 1000);
        }
      } else if (jsonData.status === 400) {
        this.$message.error(jsonData.message);
      }
    };
  }

  private handleCroll() {
    (this.$refs.crollForm as ElForm).validate((valid: boolean) => {
      if (valid) {
        this.crollLoading = false;
        this.crollDisabled = false;
        this.$message.info('데이터 수집이 시작되었습니다.');
        this.crollData();
      }
    });
  }

  private crollData() {
    const crollFormData = {
      ...this.form,
      startDate: this.form.searchDate[0],
      endDate: this.form.searchDate[1],
    };
    this.websocket.send(JSON.stringify(crollFormData));
  }
}
