







import { Component, Vue } from 'vue-property-decorator';
import CrollForm from './components/CrollForm.vue';
import CrollLog from './components/CrollLog.vue';

@Component({
  name: 'Collection',
  components: {
    CrollForm,
    CrollLog,
  },
})
export default class extends Vue {
  private handleEndCroll() {
    (this.$refs.crollLog as any).handleSearch();
  }
}
