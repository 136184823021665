
































































import { Component, Vue, Prop } from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import { getCollectionLogList } from '@/api/collection-log';

@Component({
  name: 'CrollLog',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  @Prop({ default: false }) private searchable!: boolean

  @Prop({ default: -1 }) private autoState!: number

  mounted() {
    this.getCrollLogList();
  }

  private loading = true;

  private dataList = [];

  private listQuery = {
    page: 1,
    size: 10,
    type: 'searchType',
    value: '',
    autoState: this.autoState,
  };

  private totalElements = 0;

  private getCrollLogList() {
    this.loading = true;
    getCollectionLogList(this.listQuery).then((res) => {
      this.loading = false;
      this.dataList = res.data.content;
      this.totalElements = res.data.totalElements;
    }).catch(() => {
      this.loading = false;
      this.$message.error('데이터 수집 기록을 불러오는데 실패했습니다.');
    });
  }

  private handleSearch() {
    this.listQuery.page = 1;
    this.getCrollLogList();
  }

  private handleChangePage(pageable: any) {
    this.listQuery.page = pageable.page;
    this.getCrollLogList();
  }
}
