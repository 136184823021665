import request from '@/util/request';

const PATH = '/collection-log';

export const getCollectionLogList = (listQuery: any) => request({
  url: `${PATH}`,
  method: 'get',
  params: {
    ...listQuery,
    page: listQuery.page - 1,
  },
});
